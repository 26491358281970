import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function SaveUserBtn({ userIsSaved, text, className, userId }) {
	const QCL = useQueryClient();
	const { account } = useAccount();
	const [isSaved, setIsSaved] = useState(userIsSaved);

	function handleSave(e) {
		let prom = null;
		e.preventDefault();
		e.stopPropagation();
		if (isSaved) prom = unsave(isSaved.ParticipationId);
		else prom = saveUser();
		prom.then(() => {
			QCL.refetchQueries("Lt_Employe_Saved_Employe");
		});
		return prom;
	}

	const saveUser = () =>
		genRequest(
			"LT_Employe_Saved_Employe",
			{
				EmployeId: account.EmployesId,
				SavedEmployeId: userId,
			},
			"post"
		).then((resp) => setIsSaved(resp));

	const unsave = () =>
		genRequest(
			"Lt_Employe_Saved_Employe/EmployeIdAndSavedEmpId/" +
			account.EmployesId +
			"/" +
			userId,
			null,
			"delete"
		).then(() => setIsSaved(false));

	// const fetchSave = () =>
	// 	genRequest(
	// 		`Lt_Employe_Saved_Employe/EmployeIdAndSavedEmployeId/${account.EmployesId}/${userId}`
	// 	);

	// const { isLoading } = useQuery(
	// 	[
	// 		"LT_Employe_Saved_Employe",
	// 		"EmployeIdAndSavedEmpId",
	// 		account.EmployesId,
	// 		userId,
	// 	],
	// 	fetchSave,
	// 	{
	// 		retry: 0,
	// 		enabled: userId ? true : false,
	// 		onSettled: (resp) => setIsSaved(resp ? resp : false),
	// 	}
	// );

	// if (isLoading)
	// 	return (
	// 		<div className="loading secondary">
	// 			<div className="p-2 py-3 w-100"></div>
	// 		</div>
	// 	);

	return (
		<Button
			className={
				"SaveUserBtn unstyled d-flex w-auto align-items-center" +
				(isSaved ? " saved" : "") +
				(className ? ` ${className}` : "")
			}
			onClick={handleSave}
		>
			{text ? text : false}
			<span className="icons icon-star f-14" style={{ color: isSaved ? "#ffc107" : "" }}></span>
			{/* <FontAwesomeIcon
				className={
					(text ? "ms-2 " : "") +
					"favorite-icon" +
					(isSaved ? " saved" : "")
				}
				title={
					isSaved
						? t("Commons.DEL_FAVORITE")
						: t("Commons.ADD_FAVORITE")
				}
				color={isSaved ? "#ffc107" : ""}
				icon={isSaved ? faSolidStar : faStar}
			/> */}
		</Button>
	);
}
