import React from "react";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ErrorsCont.css";

function ErrorsCont(props) {
	let errors = props.errors;

	if (!props.errors)
		return (false);
	if (typeof props.errors !== "object")
		errors = [errors];

	if (!errors?.length)
		return (false);

	return (
		<div className={"errors-cont" + (props.className ? " " + props.className : "") + (props.closable !== false && props?.setErrors ? " closable" : "")}>
			{
				(props?.setErrors) &&
				<div className="close-errors-btn cursor-pointer" onClick={() => props.setErrors(false)}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			}
			{
				errors.map((a, key) => {
					return (
						<div key={key}>
							{
								typeof a === "string" ?
									((errors.length > 1 && props.listed !== false) ? "- " : "") + a
									:
									a
							}
						</div>
					);
				})
			}
		</div>
	);
}

export function WarningsCont(props) {
	return (
		<ErrorsCont {...props} className={"warn" + (props.className ? " " + props.className : "")} />
	);
}

export default ErrorsCont;
