import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./StepsLinks.css";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

export default function StepsLinks({ instance, curStep, steps, failedStep, checkStep, isDone }) {
	const cont_ref = React.useRef();
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

	React.useEffect(() => {
		let cont = cont_ref?.current;
		let child = null;

		if (cont) {
			child = cont.querySelector(".step-link-cont:nth-child(" + curStep.activeStep + ")");
			if (child)
				cont.scrollLeft = -(cont.clientWidth / 2 + child.clientWidth / 2) + child.offsetLeft + (child.clientWidth);
		}
		return () => {
			cont = null;
			child = null;
		}
	}, []);

	if (!instance)
		return (false);

	function handleStepChange(index) {
		if (isDone || steps[index].disableLink)
			return (false);
		for (let x = 0; x < steps.length && x < index; x++) {
			if (!checkStep(steps[x].onNext, x)) {
				instance.goToStep(x + 1);
				return (false);
			}
		}
		instance.goToStep(index + 1);
	}

	function getIconClass(index) {
		if ((failedStep !== false && isDone) && index === failedStep)
			return ("checked failed");
		if ((isDone && index <= instance.state.activeStep) || (index < instance.state.activeStep))
			return ("checked");
		if (index === instance.state.activeStep)
			return ("active");
		if (failedStep !== false && index === failedStep)
			return ("failed");
		return ("");
	}

	const handleScroll = (e) => {
		let cont = cont_ref?.current;

		e.stopPropagation();
		if (cont) {
			if (cont.scrollLeft + cont.clientWidth < cont.scrollWidth)
				e.preventDefault();
			cont.scrollLeft = cont.scrollLeft + e.deltaY;
		}
	};

	return (
		<div className={clsx("steps-links", isMobile && "mobile")} ref={cont_ref} onWheel={handleScroll}>
			{
				steps.map((step, index) => {
					return (
						<div
							className={"step-link-cont " + getIconClass(index)}
							key={step.name}
						>
							<div className={"step-link" + ((step.disableLink || isDone) ? " disabled" : "")} onClick={() => handleStepChange(index)}>
								<div className={"step-index"}>
									{
										((isDone && index <= instance.state.activeStep) || (index < instance.state.activeStep && (index !== 0 || curStep?.activeStep !== 0))) ?
											<FontAwesomeIcon icon={faCheck} />
											:
											(index + 1)
									}
								</div>
								<div className="step-title" onClick={() => handleStepChange(index)}>{step.title}</div>
							</div>
						</div>
					);
				})
			}
		</div>
	);
}
