import React, { useEffect, useState } from "react";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import EditDrawer from "components/EditDrawer/EditDrawer";
import UserIdentityStep, { checkUserInfos } from "./steps/UserIndentityStep";
import UserRightsStep, { checkUserRights } from "./steps/UserRightsStep";
import UserSendStep from "./steps/UserSendStep";
import UserEndStep from "./steps/UserEndStep";
import UserDocsStep from "./steps/UserDocsStep";
import genRequest from "includes/request";

export default function UserEditDrawer(props) {
	const { user } = props;
	const { account } = useAccount();

	const EditSteps = [
		{
			component: UserIdentityStep,
			name: "identity",
			title: t("Contacts.IDENTITY_STEP"),
			onNext: checkUserInfos,
		},
		{
			component: UserRightsStep,
			name: "images",
			title: t("Contacts.RIGHTS_STEP"),
			onNext: checkUserRights,
			props: {
				adderRights: props.adderRights
			}
		},
		{
			component: UserDocsStep,
			name: "documents",
			title: t("Contacts.DOCS_STEP"),
			disabled: user?.employesId() ? false : true,
		},
		{
			component: UserSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: UserEndStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true
		}
	];

	if (!props.isOpen)
		return (false);

	return (
		<EditDrawer
			steps={EditSteps}
			onClose={props.onClose}
			object={user}
			isOpen={props.isOpen}
			account={account}
			initQueries={props.initQueries}
			title={user?.employesId() ? t("Contacts.MODIFY_TITLE") : t("Contacts.ADD_BTN")}
		/>
	);
}
