import useAccount from "classes/Accounts/hooks/useAccount";
import { motion } from "framer-motion";
import { getMenuItem } from "components/Menu/MenuItems";
import UserImage from "components/User/Image/UserImage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./MobileNavBar.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlateformChanger from "components/PlateformChanger/PlateformChanger";
import CompanyChanger from "components/CompanyChanger/CompanyChanger";
import MenuToggler from "components/MenuToggler/MenuToggler";
import { PICTOS_URL } from "includes/paths";
import useTemplate from "hooks/useTemplate";
import { t } from "i18next";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import PostObject from "classes/Posts/PostObject";
import formatDate, { dateAdd } from "includes/formatDate";
import { usePostContext } from "providers/PostProvider";
import CssFilterConverter from 'css-filter-converter';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import EmployeObject from "classes/Employes/EmployeObject";
import FormObject from "classes/Forms/FormObject";
import ChartObject from "classes/Charts/ChartObject";
import AnimationObject from "classes/Animations/AnimationObject";
import DocumentObject from "classes/Documents/DocumentObject";
import SiteObject from "classes/Sites/SiteObject";
import AppTemplate from "classes/AppTemplate/AppTemplate";
import { useQuery, useQueryClient } from "react-query";
import genRequest from "includes/request";

export default function MobileNavBar(props) {
	const myonlyall = getMenuItem("myonlyall");
	const { postTypeId, setPostToCreate } = usePostContext();
	const { isAdmin, isSup, account } = useAccount();
	const { template } = useTemplate();
	const history = useHistory();
	const location = useLocation();
	const { getAppFunction } = useTemplate();

	const slideUp = {
		hidden: {
			bottom: "-100%",
			opacity: 1,
		},
		show: {
			bottom: "0%",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			}
		}
	};
	const handleAdd = () => {
		if (document.body.classList.contains("scroll-mobilenav")) {
			history.push("/news");
		}
		document.body.classList.add("hiding-mobileMenu");
		/**
		 *  new EmployeObject({
			CompanyId: account.CompanyId,
			SiteId: isSup("SuperAdmin") >= 0 ? false : account.SiteId,
		});
		 * 
		 */
		let new_post = new PostObject({
			EmployesId: account.EmployesId,
			CompanyId: account.CompanyId,
			SiteId: account.SiteId,
			PublishedDate: formatDate(),
			LastChanged: formatDate(),
			PriorityTo: null,
			PostTypeId: postTypeId,
			Adress: window.location?.origin,
			ValidationModeration: account.ByPass_Moderation,
			account: account,
		});

		if (/^\/contacts\/?$/.test(location.pathname)) {
			new_post = new EmployeObject({
				CompanyId: account.CompanyId,
				SiteId: isSup("SuperAdmin") >= 0 ? false : account.SiteId,
			});
		}

		if (location.pathname === "/charts") {
			new_post = new ChartObject({
				CompanyId: account.CompanyId,
			});
		}

		if (location.pathname === "/agenda") {
			let date = formatDate(new Date());
			new_post = new AnimationObject({
				CompanyId: account.CompanyId,
				DateStart: date,
				DateEnd: dateAdd(date, { hours: 1 }),
				author: account,
				LinkType: "sites",
				DisplayParticipation: getAppFunction(
					"AnimationsPage.CanParticipate"
				)
			});
		}

		if (location.pathname === "/forms") {
			new_post = new FormObject({
				EmployesId: account.EmployesId,
				PublishedDate: formatDate(),
				IsDraft: false,
				CompanyId: account.CompanyId,
			});
		}

		if (location.pathname === "/documents") {
			new_post = new DocumentObject({
				CompanyId: account.CompanyId,
				SiteId: account.SiteId,
				DocTypeId: 4,
				IsShareable: getAppFunction(
					"DocumentsPage.AllowSharing"
				),
				account: account,
			});
		}

		if (location.pathname === "/sites") {
			new_post = new SiteObject({
				CompanyId: account.CompanyId,
				Template: new AppTemplate(null, template?.values())
			});
		}

		setPostToCreate(new_post);

	};
	const AddButton = ({ hideTitle = false }) => (
		<div className="head-bar-btn-cont ms-auto">
			<Button
				className="headbar-add-btn btn btn-primary gap-2"
				onClick={handleAdd}
			>
				{!hideTitle && <div className="add-btn-title">
					{t("Posts.ADD_BTN")}
				</div>}
				<span className="icons icon-edit f-25"></span>
				{/* <FontAwesomeIcon icon={faEdit} /> */}
			</Button>
		</div>
	);

	return (
		<div className="MobileNavBar">
			<AddButton hideTitle={true} />
			<Page1 {...props} myonlyall={myonlyall} />
		</div>
		// <motion.div
		// 	variants={slideUp}
		// 	initial="hidden"
		// 	animate="show"
		// 	className="MobileNavBar"
		// >
		// 	<Slider
		// 		dots={true}
		// 		appendDots={DotsNav}
		// 		arrows={false}
		// 		autoplay={false}
		// 		pauseOnHover={false}
		// 		speed={500}
		// 		infinite={true}
		// 		usageStatistics={false}
		// 		initialSlide={isSup("EloAdmin") >= 0 ? 1 : 0}
		// 	>
		// 		{isSup("EloAdmin") >= 0 && <Page3 {...props} myonlyall={myonlyall} />}

		// 		{isAdmin() && <Page2 {...props} myonlyall={myonlyall} />}
		// 	</Slider>
		// </motion.div>
	);
}

function DotsNav(dots) {
	return (
		<div>
			<div className="DotsNav">
				<ul className="m-0 p-0">
					{dots}
				</ul>
			</div>
		</div>
	);
}

function Page1(props) {
	const { template } = useTemplate();
	const { curPage, myonlyall, incrementNotifCount, items } = props;
	const CNameArr = items.reduce((acc, item) => {
		if (item.hasOwnProperty("appMenu")) {
			let menu = template.getMenus(
				item.appMenu
			);
			acc.push(menu.Title);
		}
		return acc;
	}, []);

	return (
		<div className="NavBarPage">
			{/* <MenuButton {...props} /> */}
			<NavButton {...props} titleNav={CNameArr[0]} cName="news" isActive={curPage === "/news"} item={getMenuItem("news")} />
			<NavButton {...props} titleNav={CNameArr[1]} cName="corporate" isActive={curPage === "/corporate"} item={getMenuItem("corporate")} />
			<NavButton {...props} titleNav={CNameArr[2]} cName="peoples" isActive={curPage === "/peoples"} item={getMenuItem("peoples")} />
			<NavButton {...props} titleNav={CNameArr[3]} cName="contacts" isActive={curPage === "/contacts"} item={getMenuItem("contacts")} />
			{/* <NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} /> */}
		</div>
	);
}

function MenuButton(props) {
	return (
		<NavButton
			{...props}
			item={{
				title: "Menu",
				name: "menu",
				url: "#",
				icon: PICTOS_URL + "House_Picto.png",
			}}
			callback={() => props.setIsMenuOpen(true)}
			iconComponent={() => <MenuToggler />}
		/>
	);
}

function Page2(props) {
	const { curPage, myonlyall } = props;

	return (
		<div className="NavBarPage">
			<NavButton {...props} isActive={curPage === "/analytics"} item={getMenuItem("analytics")} />
			<NavButton {...props} isActive={curPage === "/config"} item={getMenuItem("config")} />

			{document.body.clientWidth > 700 && <PlateformChanger platform={props.platform} setPlatform={props.setPlatform} />}
			<NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} />
		</div>
	);
}

function Page3(props) {
	const { curPage, myonlyall } = props;
	const { isSup } = useAccount();

	if (isSup("EloAdmin") < 0)
		return (false);

	return (
		<div className="NavBarPage justify-content-between">
			<NavButton {...props} isActive={curPage === "/devs"} item={getMenuItem("devs")} />
			<CompanyChanger className="w-75 ms-1 mb-1" menuPlacement={"top"} resetBtn={false} />
			<NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} />
		</div>
	);
}

function ProfileButton() {
	const { account } = useAccount();

	return (
		<div className="d-flex align-items-center flex-column">
			<UserImage className="mb-1" width={"32px"} res={"Url_Min"} employeId={account.EmployesId} />
		</div>
	);
}

function NavButton(props) {
	const { isSup } = useAccount();
	const { template } = useTemplate();
	const { isActive, item, callback, cName, titleNav } = props;
	const { title, icon, url, notifUrl, notifTypeId } = item;
	const [subNotifs, setSubNotifs] = React.useState(0);
	const [notifs, setNotifs] = React.useState([]);
	const { color } = CssFilterConverter.hexToFilter(template?.primaryColor());
	const QCL = useQueryClient();
	const gray = CssFilterConverter.hexToFilter("#808080");
	const parent = item.items?.length > 0 ? true : false;

	function handleClick() {
		callback(item);
	}

	function fetchNotifs() {
		if (!notifUrl) return false;
		return genRequest(
			`Me/${notifUrl}${notifTypeId ? "/" + notifTypeId : ""}`
		);
	}

	useQuery(
		["NotViewed", "Me", notifUrl, notifTypeId?.toString()],
		fetchNotifs,
		{
			enabled: !!notifUrl,
			onSuccess: (resp) => {
				const newCount = Array.isArray(resp) ? resp.length : 0;
				setNotifs(newCount);
			},
		}
	);

	useEffect(() => {
		const fetchSubNotif = async () => {
			if (item.hasOwnProperty("items") && item.items?.length > 0) {
				let tempSubNotifs = -1;
				const notifsArray = await Promise.all(
					item.items.map(async (childItem) => {
						const response = await genRequest(
							`Me/${childItem.notifUrl}${childItem.notifTypeId ? `/${childItem.notifTypeId}` : ""}`
						);
						const childCount = Array.isArray(response) ? response.length : 0;
						tempSubNotifs += childCount;
						return childCount;
					})
				);
				setSubNotifs(tempSubNotifs);
			}
		}
		fetchSubNotif();
	}, [item]);

	// const notifs = data;

	const getEnabledChilds = (items) => {
		let res = items.filter((a) => isEnabled(a));
		return (res?.length ? res : false);
	};

	const isEnabled = (page_item) => {
		if ((page_item.platform && page_item.platform !== props.platform))
			return (false);
		if (page_item.appFunction && template.getAppFunction) {
			let func = template.getAppFunction(page_item.appFunction);
			if (!func && props.platform !== "admin")
				return (false);
		}
		return (true);
	};

	if (!item || (item.role && isSup(item.role) < 0))
		return (false);

	if (item?.items) {
		let childs = getEnabledChilds(item.items);
		if (childs?.length === 1)
			return (<NavButton {...props} item={childs[0]} isActive={props.curPage === childs[0].url} />);
	}

	return (
		<div className={"NavButton classic" + (isActive ? " active" : "") + (props.className ? " " + props.className : "")} onClick={handleClick}>
			<Link className={`nav-link ${cName}`} to={url} draggable={false} >
				{
					props.child ?
						props.child
						:
						<>
							<div className="d-flex align-items-center flex-column">
								{
									props.iconComponent ?
										<props.iconComponent />
										:
										<div className="nav-button-page-icon button-bg" style={{ "backgroundImage": `url(${icon})`, filter: isActive ? color : gray.color }}></div>
								}
								<div style={{ color: template?.primaryColor() }} className="nav-button-page-title">{titleNav}</div>
							</div>
							<div className="d-flex align-items-center">
								{(notifs > 0) && <div className="notif-count">{notifs}</div>}
								{subNotifs > 0 && <div className="notif-count">{subNotifs}</div>}
							</div>
						</>
				}
			</Link>
		</div>
	);
}
