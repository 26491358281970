import React, { useState } from "react";
import { useQueryClient } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEdit,
	faCopy,
	faTrashAlt,
	faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";
import ModerationCont from "Pages/Posts/components/PostModeration/PostModeration";
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import "./EditButtons.css";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

export default function EditButtons(props) {
	const QCL = useQueryClient();
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	const { account, isAdmin } = useAccount();
	const { post, dispState } = props;
	const [rejecting, setRejecting] = useState(false);
	const { confirmDelete } = useSelect();

	const closeReject = (rejected) => {
		setRejecting(false);
		if (rejected && props.resetCur) {
			QCL.resetQueries("RetrievePosts");
			props.resetCur();
		}
	};

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	const checkRightAndCorrectAuthor = () => {
		return checkRight() ? true : post.EmployesId === account.EmployesId;
	};

	const canModify = () => {
		if (props.type === "alerts" && props.dispState === "drafts") {
			return true;
		}

		return (
			props.type !== "alerts" &&
			props.canModify !== false &&
			props.handleModify &&
			(isAdmin() || post.EmployesId === account.EmployesId)
		);
	};

	function handleDelete() {
		if (!post) return false;
		let title = t("Posts.ASKDELETE", { count: 1, title: post.Title });
		confirmDelete(
			() => {
				genRequest(
					"Posts/PostId" +
					post?.PostId +
					"AndDeleteDocument=falseAndDeleteComment=trueAndDeleteAnimation=trueAndDeleteParticipation=true",
					null,
					"delete",
					{
						toast: {
							pending: t("Posts.DELETING", { count: 1 }),
						},
					}
				).then(() => {
					QCL.refetchQueries("RetrievePosts");
					if (props.resetCur) props.resetCur();
				});
			},
			null,
			title
		);
	}

	if (rejecting) return <ModerationCont onClose={closeReject} post={post} />;

	return (
		<div className="d-flex EditButtons gap-2 flex-wrap">
			{checkRight() &&
				(dispState === "waiting" || dispState === "published") &&
				props.platform === "admin" &&
				props.moderation !== false &&
				isAdmin() &&
				props.handleReject !== false && (
					<Button
						className="btn btn-red"
						onClick={() => setRejecting(true)}
					>
						<FontAwesomeIcon className="me-2" icon={faBan} />
						{t("Commons.REJECT")}
					</Button>
				)}
			{false &&
				props.handleDuplicate &&
				(isAdmin() || post.EmployesId === account.EmployesId) && (
					<Button
						className="btn btn-blue"
						onClick={() => props.handleDuplicate(post)}
					>
						<FontAwesomeIcon className="me-2" icon={faCopy} />
						{t("Commons.DUPLICATE")}
					</Button>
				)}
			{checkRight() &&
				(isAdmin() || post.EmployesId === account.EmployesId) && (
					<Button
						className="btn btn-red"
						onClick={() => handleDelete(post)}
					>
						<span className={clsx("icons icon-trash", isMobile ? "f-15" : "f-12")}></span>
						{t("Commons.DELETE")}
					</Button>
				)}
			{checkRightAndCorrectAuthor() && canModify() && (
				<Button
					className="btn btn-blue"
					onClick={() => props.handleModify(post)}
				>
					{/* <FontAwesomeIcon className="me-2" icon={faEdit} /> */}
					<span className="icons icon-edit f-15"></span>
					{t("Commons.MODIFY")}
				</Button>
			)}
			{checkRight() && dispState !== "published" && isAdmin() && (
				<Button
					className="btn-green w-100"
					onClick={() => props.handlePublish(post)}
				>
					<FontAwesomeIcon className="me-2" icon={faPaperPlane} />
					{t("Commons.PUBLISH")}
				</Button>
			)}
		</div>
	);
}
