import React, { useEffect, useCallback } from "react";
import {
	AreaSeries,
	BarSeries,
	ColumnSeries,
	StackingAreaSeries,
	SplineAreaSeries,
	StackingColumnSeries,
	PieSeries,
} from "@syncfusion/ej2-react-charts";

import useTheme from "hooks/useTheme";
import NumberChart from "../Number/NumberChart";
import TargetChart from "../TargetChart/TargetChart";
import AccumulationChart from "../AccumulationChart/AccumulationChart";
import GaugeComponent from "../GaugeChart/GaugeChart";
import BasicChart from "../BasicChart/BasicChart";
import useUnread from "classes/Accounts/hooks/useUnread";
import { NewLabel } from "components/Tags/Label/TagLabel";
import genRequest from "includes/request";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import { bar_chart_nbr_cols } from "../../../classes/Charts/Types/displayer/bar_charts_displayer";

export default function ChartTile(props) {
	const { theme } = useTheme();
	const { isReaded, setAsReaded } = useUnread();

	let chart = props.data;
	let readed_chart = isReaded("NotViewedChart", chart.ChartId);

	const incrementViews = useCallback(() => {
		if (!chart?.ChartId || readed_chart) return Promise.resolve(true);
		let req = genRequest(
			"Charts/IncrementView/" + chart.ChartId,
			null,
			"put"
		)
			.then((resp) => {
				return resp;
			})
			.catch(() => { });
		return req;
	}, [chart, readed_chart]);

	const handleClick = useCallback(() => {
		incrementViews().then(() => {
			setAsReaded("NotViewedChart", chart.ChartId);
		});
	}, [incrementViews, setAsReaded, chart]);

	useEffect(() => {
		if (!isReaded("NotViewedChart", chart.ChartId)) handleClick();
	}, [isReaded, chart, handleClick]);

	let values;

	try {
		values = JSON.parse(chart.Chartvalues);
		chart.parsedValues = values;
	} catch (e) {
		return false;
	}
	if (!values) return false;
	let specs = getChartSpecs(chart, chart.ChartTypeId, values);
	let component = getComponent(theme, chart, specs, values);

	if (!component) return false;
	return (
		<div
			className={
				"tile title-form chart-tile fixed-tile" +
				(props.className ? " " + props.className : "") +
				(specs.nbr_vals > 3 ? " w-100" : "")
			}
			onClick={handleClick}
		>
			<div className="d-flex align-items-center justify-content-center">
				{!readed_chart && !props.editing && (
					<NewLabel className="me-1" />
				)}
				<div className="font-bold text-center">{chart.Title}</div>
			</div>
			<QueryTagsList
				queryKey={["Categories", "Chart", chart.ChartId]}
				queryFn={() => genRequest("Categories/Chart/" + chart.ChartId)}
			/>
			<div className="w-100">{component}</div>
			<SyncfusionFix chartId={chart.ChartId} />
		</div>
	);
}

function SyncfusionFix(chartId) {
	let elem = document.getElementById(
		chartId + "Keyboard_accumulationchart_focus"
	);
	if (elem) return false;
	return (
		<div
			id={chartId + "Keyboard_accumulationchart_focus"}
			className="syncfusion-fix"
		/>
	);
}

function getComponent(theme, chart, specs, values) {
	let tile_theme = gestChartTheme(theme, chart, values);
	let chart_props = {
		chart,
		specs,
		values,
		theme: tile_theme,
	};
	if (chart.ChartTypeId === 4)
		return <TargetChart {...chart_props} {...chart} theme={tile_theme} />;
	if (chart.ChartTypeId === 7)
		return <NumberChart {...chart} {...values[0]} theme={tile_theme} />;
	if (chart.ChartTypeId >= 9 && chart.ChartTypeId <= 16)
		return <BasicChart {...chart_props} />;
	if (chart.ChartTypeId >= 17 && chart.ChartTypeId <= 18)
		return <AccumulationChart {...chart_props} />;
	if (chart.ChartTypeId >= 19 && chart.ChartTypeId <= 20)
		return <GaugeComponent {...chart_props} />;
	return false;
}

function getNbrVals(chart, values) {
	if ([9, 10, 12, 13, 14, 15, 16].indexOf(chart.ChartTypeId) !== -1)
		return bar_chart_nbr_cols(values.series);
	return 0;
}

function getChartSpecs(chart, chartTypeId, values) {
	let nbr_vals = getNbrVals(chart, values);
	let chartTypesServices = [
		{ TypesId: [9], Services: [BarSeries], Type: "Bar", nbr_vals },
		{ TypesId: [10], Services: [ColumnSeries], Type: "Column", nbr_vals },
		{
			TypesId: [12],
			Services: [SplineAreaSeries],
			Type: "SplineArea",
			nbr_vals,
		},
		{
			TypesId: [13],
			Services: [StackingAreaSeries],
			Type: "StackingArea",
			nbr_vals,
		},
		{ TypesId: [14], Services: [AreaSeries], Type: "Area", nbr_vals },
		{
			TypesId: [15],
			Services: [StackingAreaSeries],
			Type: "StackingArea100",
			nbr_vals,
		},
		{
			TypesId: [16],
			Services: [StackingColumnSeries],
			Type: "StackingColumn",
			nbr_vals,
		},
		{ TypesId: [17, 18], Services: [PieSeries], Type: "Pie", nbr_vals },
		{ TypesId: [19], Services: [], Type: "Pie", nbr_vals },
		{ TypesId: [20], Services: [PieSeries], Type: "Pie", nbr_vals },
	];

	let ret = chartTypesServices.find(
		(a) => a.TypesId.indexOf(chartTypeId) !== -1
	);
	return ret ? ret : false;
}

function gestChartTheme(theme, chart, values) {
	let color = theme === "light" ? "black" : "white";

	if (!values) return false;
	let ret = {
		...values,
		background: "transparent",
		legendSettings: {
			textStyle: {
				color: color,
				fontFamily: "Poppins-Light",
			},
		},
		subTitleStyle: {
			color: color,
		},
		labelStyle: {
			fontFamily: "Poppins-Light",
		},
	};

	if (ret.primaryXAxis)
		ret.primaryXAxis = {
			...ret.primaryXAxis,
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		};

	if (ret.primaryYAxis)
		ret.primaryYAxis = {
			...ret.primaryYAxis,
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		};

	if (ret.series && [17, 18].indexOf(chart.ChartTypeId) === -1) {
		ret.series = ret.series.map((a, b) => {
			if (a.marker?.dataLabel) {
				if ([10].indexOf(chart.ChartTypeId) === -1)
					a.marker.dataLabel.font.color = color;
				if (chart.ChartTypeId === 13) {
					if (b < ret.series.length - 1)
						a.marker.dataLabel.font.color = color;
				}
				a.marker.dataLabel.font.fontFamily = "Poppins-Regular";
			}
			return a;
		});
	} else {
		ret.legendSettings = {
			...ret.legendSettings,
			position: "Bottom",
			alignment: "Center",
		};
	}
	delete ret.title;
	return ret;
}
