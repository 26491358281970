import React from "react";

import { t } from "i18next";
import "./PostEditDrawer.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import EditDrawer from "components/EditDrawer/EditDrawer";
import PostContentStep, { checkPostContent } from "./components/Content/PostContentStep";
import PostImagesStep, { checkPostImages } from "./components/Images/PostImagesStep";
import PostSendOptionsStep, { checkTargets } from "./components/SendOptions/PostSendOptionsStep";
import PostSendStep from "./components/SendStep/PostSendStep";
import PostEndStep from "./components/EndStep/PostEndStep";
import AttachementsStep from "./components/AttachementsStep/AttachementsStep";
import PostComplementStep from "./components/Complement/PostComplementStep";
import getAlertsSteps from "./components/Alerts/AlertSteps";
import PostPreview from "../PostPreview/PostPreview";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";

export default function PostEditDrawer(props) {

	const { account, isAdmin } = useAccount();

	const isModeration = ["waiting", "moderated"].indexOf(props.dispState) >= 0;

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		return returnRightsFromName(account.Rights, rigthName) ? false : true;
	};

	const PostSteps = [
		{
			component: PostContentStep,
			name: "content",
			title: t("Posts.CONTENT_STEP"),
			onNext: checkPostContent,
		},
		{
			component: PostComplementStep,
			name: "complement",
			title: t("Posts.POST_RESPONSE"),
			disabled: (props.post?.postId?.() && isAdmin()) ? false : true,
		},
		{
			component: PostImagesStep,
			name: "images",
			title: t("Posts.IMAGES_STEP"),
			onNext: checkPostImages,
			disabled: (props.images === false),
			props: {
				isRss: props.dispState === "rss"
			}
		},
		{
			component: AttachementsStep,
			name: "documents",
			title: t("Posts.ATTACH_STEP"),
			disabled: (props.linkedDocs === false || !isAdmin()),
		},
		{
			component: PostSendOptionsStep,
			name: "sendOptions",
			title: t("Commons.PUBLISH_OPTIONS"),
			disabled: checkRight(),
			onNext: checkTargets
		},
		{
			component: PostSendStep,
			name: "send",
			// disableLink: true,
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: PostEndStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true,
			props: {
				dispDrafts: props.dispDrafts,
				onClose: props.onClose
			}
		}
	];

	const getTitle = () => {
		if (props.title)
			return (props.title);
		if (props.dispState === "published") {
			if (props.post?.postId())
				return (t("Posts.MODIFY_TITLE"));
			else
				return (t("Posts.ADD_TITLE"));
		} else if (isModeration) {
			return (t("Posts.PUBLISH_TITLE"));
		}
		return (t("Posts.EDIT_TITLE"));
	};

	const getCurStep = () => {
		if (
			isModeration && ["modify", "add"].indexOf(props.editAction) < 0
		) {
			return (5);
		}
		return (1);
	};

	let isAuthor = props.post.employeId() === account.EmployesId;

	const handleDraft = (draft_props) => {
		let post = draft_props.object;
		if (isAuthor)
			post.setAsDraft();
		else if (isAdmin())
			post.setAsToModerate();
		return (true);
	}

	if (!props.isOpen)
		return (false);

	return (
		<EditDrawer
			steps={props.post.postTypeId() !== 13 ? PostSteps : getAlertsSteps(props)}
			onClose={props.onClose}
			onDraft={isAuthor || isModeration ? handleDraft : false}
			draftBtnText={isAuthor && !isModeration ? false : t("EditDrawer.SAVE_NO_PUBLISH_BTN")}
			object={props.post}
			isOpen={props.isOpen}
			account={props.account}
			initQueries={props.initQueries}
			initialStep={getCurStep()}
			title={getTitle()}
			editPreview={EditPostPreview}
		/>
	);
}

const EditPostPreview = (props) => {
	const post = props.object;

	let cats = post.categories().map((a) => ({
		text: a.Name,
		color: a.DefaultHexaColor,
		title: a.Description
	}));

	let post_vals = post.values(true);

	let docs = post.documents().map((a) => a.values(true));
	let resp = post.response()?.values();
	let forms = post.linkedForms();

	return (
		<PostPreview
			isOpen={true}
			post={post_vals}
			images={post.images()}
			response={resp?.Txt?.length ? resp : {}}
			categories={cats}
			documents={docs}
			forms={forms}
			stats={post.postId() ? true : false}
			extend={false}
		/>
	);
};
