import React, { useEffect, useState, useRef } from "react";
import Konami from "konami";
import "./App.css";
import "./fonts.css";
import useToken from "classes/Accounts/hooks/useToken";
import ForgetPage from "Pages/Login/ForgetPage/ForgetPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BrainWrapper from "components/BrainWrapper/Wrapper";
import { ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.min.css";
import LoginPage from "Pages/Login/LoginPage";
import Storage from "classes/Storage/Storage";
// import useMenu from "hooks/useMenu";
import useAccount from "classes/Accounts/hooks/useAccount";
import ReactModal from "react-modal";
import useEditing from "components/EditDrawer/useEditing";
import { AccountProvider } from "providers/AccountProvider";
import { FilterProvider } from "providers/FilterProvider";
import { PostProvider } from "providers/PostProvider";
import { installationPrompt } from "components-v2/PWA/pwaPrompt.component";
import { manualPrompt } from "components-v2/PWA/pwaManualPrompt.component";
import "components-v2/PWA/pwaCheck.styles.css";
import {
	APP_INSTALLED,
	BEFORE_INSTALL_PROMPT,
	INSTALLATION_MESSAGE,
	INSTALLATION_STATUS,
	getDeviceInfo,
} from "includes/pwa_helpers";
import { UAParser } from "ua-parser-js";
// import CreatePage from "Pages/Create/Create";

// eslint-disable-next-line
const easterEgg = new Konami("https://shimos.fr/?boot");

export default function App() {
	const { token } = useToken();
	const storage = new Storage();
	const { account, setSettings, getSettings } = useAccount();

	const { device, pwa } = getDeviceInfo(new UAParser());
	const deferredPrompt = useRef(null);

	const appInstalled = () => {
		localStorage.setItem(INSTALLATION_STATUS, INSTALLATION_MESSAGE);
		deferredPrompt.current = null;
	};
	const doNotShowAgain = (close) => {
		appInstalled();
		close();
	};
	const alreadyInstalled = doNotShowAgain;
	const onInstall = (close) => {
		if (deferredPrompt.current) {
			deferredPrompt?.current.prompt();
			// Wait for the user to respond to the prompt
			let status = false;
			deferredPrompt.current.userChoice.then((choiceResult) => {
				status = choiceResult.outcome === "accepted";
			});
			if (status) {
				appInstalled();
				deferredPrompt.current = null;
			}
			close();
		}
	};

	const OneSignal = window.OneSignal || [];

	useEffect(() => {
		if (OneSignal.hasOwnProperty("push") && account?.AspNetUserId) {
			// Vérifiez si les notifications sont activées
			OneSignal.isPushNotificationsEnabled()
				.then((isEnabled) => {
					if (isEnabled) {
						console.log("Push notifications are enabled!");
					} else {
						console.log("Push notifications are not enabled yet.");
					}
				})
				.catch((error) => {
					console.error(
						"Error checking push notification status:",
						error
					);
				});
			OneSignal.push(function () {
				console.log(
					"Setting OneSignal External User ID : ",
					account.AspNetUserId
				);
				OneSignal.setExternalUserId(account.AspNetUserId)
					.then(() => {
						console.log(
							`External User ID set to: ${account.AspNetUserId}`
						);
					})
					.catch((error) => {
						console.error("Error setting external user ID:", error);
					});
			});
		}
	}, [OneSignal]);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker
				.getRegistration("/OneSignalSDKWorker.js")
				.then((registration) => {
					if (registration) {
						console.log(
							"Service Worker registration:",
							registration
						);
						if (registration.active) {
							console.log(
								"Service Worker is active:",
								registration.active
							);
						} else {
							console.error("Service Worker is not active");
						}
					} else {
						console.error("Service Worker not registered");
					}
				})
				.catch((error) => {
					console.error(
						"Error checking Service Worker registration:",
						error
					);
				});
		}
	}, []);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker
				.register("/OneSignalSDKWorker.js", { scope: "/" })
				.then((registration) => {
					console.log(
						"Service Worker registered manually:",
						registration
					);
				})
				.catch((error) => {
					console.error("Service Worker registration failed:", error);
				});
		}
	}, []);

	// PWA Installation Prompt

	useEffect(() => {
		window.addEventListener(BEFORE_INSTALL_PROMPT, (e) => {
			e.preventDefault();
			deferredPrompt.current = e;
		});
		window.addEventListener(APP_INSTALLED, appInstalled);
	}, []);

	useEffect(() => {
		// Trigger PWA installation prompt on mobile devices only
		const isInstallable =
			device.isMobile || (device.isMobile && !pwa.isStandalone(window));
		const isInstalled = localStorage.getItem(INSTALLATION_STATUS);
		setTimeout(() => {
			if (isInstallable && !isInstalled)
				if (deferredPrompt.current) {
					installationPrompt({
						doNotShowAgain,
						onInstall,
					});
				} else {
					manualPrompt({
						doNotShowAgain,
						alreadyInstalled,
						isIOS: device.isIOS,
						isAndroid: device.isAndroid,
					});
				}
		}, 1e3);
	}, [device.isAndroid, device.isIOS, deferredPrompt.current]);

	const [langCode, setLang] = useState(getNavigatorLanguage());
	useEditing(false);

	function changeLang(code) {
		if (account?.CompanyId) setSettings("Language", code);
		else {
			localStorage.setItem("lang-code", code);
			window.location.reload();
		}
	}

	function getNavigatorLanguage() {
		let lang = navigator.language || navigator.userLanguage;

		if (account) {
			let from_settings = getSettings("Language");
			if (from_settings) return from_settings;
		}
		let from_local = localStorage.getItem("lang-code");
		if (from_local) return from_local;
		if (!lang) return "en-EN";
		if (lang.indexOf("-") === -1) lang = lang + "-" + lang.toUpperCase();
		return lang;
	}

	const get_load_img = () => storage.getItem("comp_image");

	if (!token)
		return (
			<Router>
				<Switch>
					{/* <Route exact path={"/create"}>
						<CreatePage setLangCode={setLang}/>
					</Route> */}
					<Route exact path="/forget">
						<ForgetPage setLangCode={setLang} />
					</Route>
					<Route exact path={["/:accountId", "*"]}>
						<LoginPage setLangCode={setLang} />
					</Route>
				</Switch>
			</Router>
		);

	return (
		<AccountProvider>
			<PostProvider>
				<FilterProvider>
					{/* <ReactPWAInstallProvider enableLogging> */}
					<div
						ref={(ref) => ReactModal.setAppElement(ref)}
						className="wrapper"
						style={{
							backgroundImage: `url(${
								get_load_img()?.Url_Origin
							})`,
						}}
					>
						<ToastContainer
							position="top-right"
							autoClose={7000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick={true}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<BrainWrapper
							code={langCode}
							setLangCode={changeLang}
							// getMenuItems={getMenuItems}
							// getMenuItem={getMenuItem}
						/>
					</div>
					{/* </ReactPWAInstallProvider> */}
				</FilterProvider>
			</PostProvider>
		</AccountProvider>
	);
}
