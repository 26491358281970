import React from "react";
import MarkdownEditor from "components/MardownEditor/MarkdownEditor";
import { t } from "i18next";

function AnimationDescStep(props) {
	let cur_anim = props.object;
	let linkedPost = cur_anim.linkedPost();

	return (
		<div className="inner-step-cont">
			<div className="mt-3 px-2">
				<div className="title-form mb-1">
					{t("Events.EVENT_DESC")}
				</div>
				<MarkdownEditor
					initialValue={linkedPost.txt()}
					onChange={(val) => {
						linkedPost.txt(val)
					}}
				/>
			</div>
		</div>
	);
}

export function checkAnimDesc(anim) {
	let linkedPost = anim.linkedPost();
	let text = linkedPost.txt();

	if (!text?.length)
		linkedPost.txt(anim.title());
	return (true);
}

export default AnimationDescStep;
