import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./StepsNav.css";
import { t } from "i18next";
import useEditing from "components/EditDrawer/useEditing";
import Button from "components/Button/Button";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

export default function StepsNav(props) {
	const { getEditing, setEditing, checkEditing } = useEditing();

	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

	useEffect(() => {
		document.body.classList.add("hiding-mobileMenu");
		return () => {
			document.body.classList.remove("hiding-mobileMenu");
		};
	}, []);

	function handleStepChange(dir, props) {
		let steps = props.steps;
		let cur_step = props.currentStep;
		let chart = props.object;
		if (steps[cur_step].sendStep) {
			if (!typeof chart.isDraft) {
				chart.isDraft(false);
			}
		}

		if (dir === "next" || dir === "finish") {
			if (!props.checkStep(steps[cur_step - 1].onNext, cur_step - 1))
				return false;
			if (dir === "next") props.nextStep();
			else props.lastStep();
		} else if (dir === "prev" && cur_step > 1) props.previousStep();
		return false;
	}

	function gotToStep(stepId) {
		let steps = props.steps;
		for (let x = 0; x < steps.length; x++) {
			if (x > stepId) {
				props.goToStep(x);
				return false;
			}
			let check = props.checkStep(steps[x].onNext, x);
			if (!check) {
				props.goToStep(x + 1);
				return false;
			}
		}
	}

	function goToSendStep() {
		let steps = props.steps;

		if (steps) {
			let step = steps
				.map((a, index) => {
					if (a.sendStep) return { ...a, index };
					return false;
				})
				.filter((a) => a)[0];

			if (step) gotToStep(step.index);
			else console.error("No Send Step found");
		}
	}

	function handleDraft() {
		let step_check = false;
		if (props.onDraft) {
			let check = props.onDraft(props);
			if (check) step_check = goToSendStep();
		} else {
			step_check = goToSendStep();
		}
		if (step_check) setEditing(false);
	}

	function handleClose() {

		if (!props.isDone) {
			if (getEditing())
				checkEditing(
					() => {
						close();
					},
					() => { },
					props.onDraft
						? () => {
							handleDraft();
						}
						: false
				);
			else {
				close();
			}
		} else {
			close();
		}

	}

	const close = () => {
		setEditing(false);
		if (props.onClose) props.onClose(props.isDone);
	};

	let current_step = props.currentStep;
	let step_obj = props.steps[current_step - 1];
	let next_step = props.steps[current_step];
	let disabled = step_obj?.disableNav || false;

	return (
		<>
			{(!props.isDone && isMobile) && (
				<div className="close-btn-edit">
					<Button
						disabled={disabled}
						className="btn btn-red"
						onClick={handleClose}
					>
						<div>
							<span className="icons icon-close"></span>
						</div>
					</Button>
				</div>
			)}
			<div className={clsx("steps-nav mx-0 gap-2", isMobile && "mobile")}>
				{(!props.isDone && !isMobile) && (
					<div className=" close-btn-edit">
						<Button
							disabled={disabled}
							className="btn btn-red"
							onClick={handleClose}
						>
							{t("Commons.CLOSE")}
						</Button>
					</div>
				)}
				{!props.isDone && isMobile && (
					<div>
						<Button
							disabled={disabled}
							className={clsx("btn btn-grey justify-content-between", props.currentStep === 1 && "disabled")}
							onClick={() => handleStepChange("prev", props)}
						>
							<div>
								<span className="icons icon-prev"></span>
								{t("EditDrawer.PREV_BTN")}
							</div>



						</Button>
					</div>
				)}
				{!props.isDone && props.onDraft && (
					<div>
						<Button
							disabled={disabled}
							className="btn btn-yellow"
							onClick={() => handleDraft(props)}
						>
							{isMobile ? <div>
								<span className="icons icon-draft"></span>
								{t("EditDrawer.DRAFT_BTN_MOBILE")}
							</div> : props.draftBtnText || t("EditDrawer.DRAFT_BTN")}
						</Button>
					</div>
				)}
				{!props.isDone && props.currentStep > 1 && !isMobile && (
					<div>
						<Button
							disabled={disabled}
							className="btn btn-grey justify-content-between"
							onClick={() => handleStepChange("prev", props)}
						>
							{isMobile ? <div>
								<span className="icons icon-prev"></span>
							</div> :
								<>
									<FontAwesomeIcon icon={faChevronLeft} />
									{t("EditDrawer.PREV_BTN")}
									<FontAwesomeIcon
										icon={faChevronRight}
										color="transparent"
									/>
								</>
							}


						</Button>
					</div>
				)}

				{props.preview && !props.isDone && (
					<div>
						<Button
							className="btn btn-cyan"
							onClick={() =>
								props.setIsPreviewOpen(!props.isPreviewOpen)
							}
						>
							{isMobile ? <div>
								<span className="icons icon-look"></span>
								{t("EditDrawer.PREVIEW_STEP")}
							</div> :
								t("EditDrawer.PREVIEW_STEP")
							}
						</Button>
					</div>
				)}
				{props.currentStep !== props.totalSteps && (
					<div>
						<Button
							disabled={disabled && !props.isDone}
							className={`btn ${next_step?.sendStep || current_step.sendStep
								? "btn-green"
								: "btn-blue"
								} justify-content-between`}
							onClick={() => handleStepChange("next", props)}
						>
							{isMobile ? <div>
								<span className="icons icon-next"></span>
								{props.sendBtnText &&
									(next_step?.sendStep || current_step.sendStep)
									? props.sendBtnText
									: t(
										`EditDrawer.${next_step?.sendStep ||
											current_step.sendStep
											? "SEND_BTN"
											: "NEXT_BTN"
										}`
									)}
							</div> :
								<>
									<FontAwesomeIcon
										icon={faChevronLeft}
										color="transparent"
									/>
									{props.sendBtnText &&
										(next_step?.sendStep || current_step.sendStep)
										? props.sendBtnText
										: t(
											`EditDrawer.${next_step?.sendStep ||
												current_step.sendStep
												? "SEND_BTN"
												: "NEXT_BTN"
											}`
										)}
									<FontAwesomeIcon icon={faChevronRight} />
								</>
							}

						</Button>
					</div>
				)}
				{/* {props.isDone && (
					<div>
						<Button
							className="btn btn-green"
							onClick={() => handleClose(props)}
						>
							{t("EditDrawer.END_BTN")}
						</Button>
					</div>
				)} */}
			</div>
		</>
	);
}
