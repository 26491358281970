import React, { useRef, useState, } from "react";
import { dataURLtoFile } from "includes/documents_treat";
import { Droppable } from "react-beautiful-dnd";
import { ImageObject, retrieveImageFile } from "../../ImagesSender";
import ImagesItems from "../ImagesItems/ImagesItems";
import "./ImagesRow.css";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";

export default function ImagesRow(props) {
	let images_ref = useRef();
	const [isTooBig, setIsTooBig] = useState(false);

	function handleScroll(e) {
		e.stopPropagation();
		let row_cont = images_ref.current;
		row_cont.scrollLeft = row_cont.scrollLeft + e.deltaY;
	}

	function handleFile(ev) {
		ev.stopPropagation();
		ev.preventDefault();
		let files = ev.target.files;
		if (!files.length)
			return (false);
		if (props.setCurImage) {
			Resizer.imageFileResizer(
				files[0],
				1500,
				1000,
				"JPEG",
				100,
				0,
				(resizedFile) => {
					props.setCurImage(new ImageObject({ file: resizedFile, IsNew: true }));
				}
			);
		}
		ev.target.value = "";
	}

	function handleDrop(e) {
		e.stopPropagation();
		e.preventDefault();

		if (props.setCurImage)
			props.setCurImage(false);
		images_ref.current.classList.remove("drag-over");
		let files = e.dataTransfer.files;
		if (!files.length)
			return (false);
		if (props.setCurImage) {
			Resizer.imageFileResizer(
				files[0],
				1500,
				1000,
				"JPEG",
				100,
				0,
				(resizedFile) => {
					props.setCurImage(new ImageObject({ file: resizedFile, IsNew: true }));
				}
			);
		}
	}

	function handleDragOver(e, laeving) {
		e.stopPropagation();
		e.preventDefault();
		if (laeving)
			images_ref.current.classList.remove("drag-over");
		else
			images_ref.current.classList.add("drag-over");
	}

	function handleClick(img) {
		if (img.DocumentId && !img.DocumentFile) {
			retrieveImageFile(img.DocumentId).then((documentFile) => {
				let res = "data:image/png;base64," + documentFile;
				let file_img = dataURLtoFile(res, img.Name);
				props.setCurImage(new ImageObject({ file: file_img, DocumentFile: img.DocumentFile, Name: img.Name, DocumentId: img.DocumentId, TempId: img.TempId }));
			});
		} else if (img.DocumentFile) {
			let docFile = img.DocumentFile;
			if (img.DocumentFile.indexOf("data:image") === -1)
				docFile = "data:image/png;base64," + docFile;
			let file_img = dataURLtoFile(docFile, img.Name, img.DocumentId);
			props.setCurImage(new ImageObject({ file: file_img, DocumentFile: img.DocumentFile, Name: img.Name, DocumentId: img.DocumentId, TempId: img.TempId }));
		}
	}

	return (
		<>
			<div className="images-cont">
				<div className="images-row" ref={images_ref} onWheel={handleScroll} onDrop={handleDrop} onDragLeaveCapture={(e) => handleDragOver(e, true)} onDragOver={handleDragOver}>
					{props.rssImages && props.rssImages.length > 0 && (

						<div className="rssImages">
							<div className="image-preview" style={{ backgroundImage: `url(${props.rssImages[0]})` }}>
								<div
									className="del-image-btn"
									title={t("Images.RM_IMAGE")}
									onClick={(e) => {
										e.preventDefault(); e.stopPropagation();
										props.removeRssImage();
									}}
								>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							</div>
						</div>
					)}
					{/* {!props.isMobile && */}
					<Droppable droppableId="images-row" direction="horizontal">
						{
							(provided) => {
								return (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										<ImagesItems handleDelete={props.handleDelete} images={props.images} onClick={handleClick} />
										{provided.placeholder}
									</div>
								);
							}
						}
					</Droppable>
					{/* } */}
				</div>
				<div className="upload-post-image-btn" title={t("Images.IMAGE_OVERLAY")}>
					<div className="add-image-input-cont">
						<input className="add-image-input" type="file" accept=".png, .jpeg, .jpg" onChange={handleFile} title={t("Images.IMAGE_OVERLAY")} />
						<span className="info-size">2Mb max</span>

					</div>
					<div className="d-flex align-items-center justify-content-evenly flex-column gap-2">
						<div className="import-msg">{t("Images.ADD_IMAGE")}</div>
						<div className="import-plus-btn">+</div>
					</div>
				</div>
			</div>
			{isTooBig && <div className="error-msg">{t("Images.IMAGE_SIZE_ERROR")} {t("Images.IMAGE_SIZE_ERROR_2")} 2Mb</div>}
		</>
	);
}
