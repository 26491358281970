import genRequest from "includes/request";
import React from "react";
import { useQuery, useQueryClient } from "react-query";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";

export default function SavePostBtn(props) {
	const { account } = useAccount();
	const { postId } = props;
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	const QCL = useQueryClient();
	let isUpdating = false;

	const retrieveUserSavePost = () => {
		if (!postId)
			return (false);
		let req = genRequest(
			"LtEmployeSavedposts/" + account.EmployesId + "/" + postId
		).then((resp) => {
			return (resp);
		}, () => {
			return (false);
		});
		return (req);
	};

	const gestSavePost = () => {
		if (isUpdating)
			return (isUpdating);
		let req = savePost().then(() => {
			isUpdating = false;
			QCL.refetchQueries(["LtEmployeSavedposts", account.EmployesId, postId]);
		});
		isUpdating = req;
		return (req);
	};

	const savePost = () => {
		if (!postId)
			return (false);
		if (data)
			return (unSavePost());
		const req = genRequest(
			"LtEmployeSavedposts",
			{
				EmployesId: account.EmployesId,
				PostId: postId
			},
		).then((resp) => {
			return (resp);
		});
		return (req);
	};

	const unSavePost = () => {
		if (!postId)
			return (false);
		const req = genRequest(
			"LtEmployeSavedposts/EmployeIdAndPostId/" + account.EmployesId + "/" + postId,
			null,
			"delete"
		).then((resp) => {
			return (resp);
		});
		return (req);
	};

	const { isLoading, data } = useQuery(
		["LtEmployeSavedposts", account.EmployesId, postId],
		retrieveUserSavePost,
		{ retry: 0, enabled: (postId ? true : false) }
	);

	if (props.hideIfNotSaved && !data)
		return (false);

	return (
		<div
			className={`post-saved-cont d-flex align-items-center cursor-pointer ${props.className || ""}`}
			style={props.style}
			title={t(data ? "Posts.SAVED_POST" : "Posts.SAVE_POST")}
			onClick={gestSavePost}
		>
			{
				(!props.hideIfNotSaved || data) &&
				// <div>
				<span className={clsx("icons icon-star", isMobile ? "f-25" : "f-12")} style={{ color: data ? "#ffc107" : "" }}>

					{/* <FontAwesomeIcon icon={data ? faSolidStar : faStar} color={data ? "#ffc107" : ""} /> */}
				</span>
				// </div>
			}
			{
				!props.iconOnly && (
					isLoading ?
						<div className="ms-1 d-flex align-items-center loading">
							<div style={{ width: "10px", height: "5px" }}></div>
						</div>
						:
						<div className="post-likes-count ms-1">{data ? t("Posts.FAVORITE") : ""}</div>
				)
			}
		</div >
	);
}
