import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import genRequest from "includes/request";
import useUnread from "classes/Accounts/hooks/useUnread";
import "./PageLink.css";
import "./themes/DefaultPageLink.css";
import { usePopper } from "react-popper";

function PageLink(props) {
	const { item, parent } = props;
	const { inMenu, notifUrl, notifTypeId } = item;
	const { setItems } = useUnread();
	const QCL = useQueryClient();

	function fetchNotifs() {
		if (!notifUrl) return false;
		return genRequest(
			`Me/${notifUrl}${notifTypeId ? "/" + notifTypeId : ""}`
		);
	}

	const { data } = useQuery(
		["NotViewed", "Me", notifUrl, notifTypeId?.toString()],
		fetchNotifs,
		{
			enabled: notifUrl ? true : false,
			onSuccess: (resp) => {
				setItems(
					`${notifUrl}${notifTypeId ? "_" + notifTypeId : ""}`,
					resp
				);
				if (parent)
					QCL.refetchQueries([
						"NotViewed",
						"PagesGroup",
						parent.name,
					]);
			},
		}
	);

	if (inMenu === false) return false;

	return (
		<div
			className={
				`PageLink ${item.name + "-page-link"}` +
				(props.isActive ? " active" : "")
			}
		>
			<PageButton {...props} notifs={data} />
		</div>
	);
}

export function PageButton(props) {
	const { isActive, item, onClick, notifs, isGroup, onArrowClick } = props;
	const { title, platform, adminTitle, icon, cName, url, isVisible } = item;
	const [tooltip, setTooltip] = useState(false);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "right",
		strategy: "fixed",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 15],
				},
			},
		],
	});

	function handleClick(e) {
		onClick(item, item.forceRefresh, e);
	}

	return (
		<>
			{isVisible && (
				<div
					className={
						"PageButton" +
						(isActive ? " active" : "") +
						(props.className ? " " + props.className : "")
					}
					onClick={handleClick}
					onMouseOver={() => setTooltip(true)}
					onMouseLeave={() => setTooltip(false)}
				>
					<Link
						className={`nav-link ${cName ? cName : ""}`}
						to={url}
						ref={setReferenceElement}
					>
						<div className="nav-link-infos d-flex align-items-center">
							<div
								className="nav-link-page-icon button-bg"
							// style={{ backgroundImage: `url(${icon})` }}
							></div>
							<div className="nav-link-page-title">
								{adminTitle && platform === "admin"
									? adminTitle
									: title}
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-center gap-2">
							{notifs?.length > 0 && (
								<div
									className={
										"notif-count" +
										(notifs?.length > 99 ? " over99" : "")
									}
								>
									{notifs?.length <= 99 ? notifs.length : 99}
								</div>
							)}
							{isGroup && (
								<div
									className="pages-group-arrow"
									onClick={onArrowClick}
								>
									<FontAwesomeIcon icon={faChevronRight} />
								</div>
							)}
						</div>
					</Link>
					{tooltip && (
						<div
							className="page-button-tooltip"
							ref={setPopperElement}
							onMouseLeave={() => setTooltip(false)}
							style={styles.popper}
							{...attributes.popper}
						>
							{title}
						</div>
					)}
				</div>
			)}
		</>
	);
}

export function CustomPageLink(props) {
	const { to, activeOnlyWhenExact, className, style, onClick } = props;

	let match = useRouteMatch({
		path: to,
		exact: activeOnlyWhenExact,
	});

	return (
		<div
			className={(match ? "active" : "") + " " + className}
			style={style}
			onClick={onClick}
		>
			<Link to={to} className={className}>
				{props.children}
			</Link>
		</div>
	);
}

export default PageLink;
