import React, { useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import genRequest from "includes/request";
import { t } from "i18next";
import noAccent from "includes/no_accents";
import "./Select.css";

function CustomAsyncSelect(props) {
	const [prevTM, setPrevTM] = useState();
	const [lastOptions, setLastOptions] = useState([]);

	const search = () =>
		genRequest(props.url, props.data, props.method, {
			forceUrl: props.forceUrl,
		}).then((resp) => {
			if (!resp?.length) return false;
			if (!props.treat) return resp;
			let ret = resp
				.map((a) => props.treat(a))
				.filter((a) => a)
				.sort((a, b) =>
					noAccent(a.label.toLowerCase()) <
						noAccent(b.label.toLowerCase())
						? -1
						: 1
				);
			setLastOptions(ret);
			return ret;
		});

	const handleSelect = (result) => {
		let timeout;
		clearTimeout(prevTM);
		timeout = setTimeout(
			() => {
				if (props.onSelect)
					props.onSelect(result.value ? result.value : result);
			},
			props.timeout >= 0 ? props.timeout : 1000
		);
		setPrevTM(timeout);
	};

	return (
		<div
			className={
				"CustomAsyncSelect custom-select w-100" +
				(props.className ? " " + props.className : "")
			}
		>
			{props.title?.length && (
				<div className={"title" + (!props.text ? " mb-1" : "") + " title-form"}>
					{props.title}
				</div>
			)}
			{props.text && <div className="text mb-1">{props.text}</div>}
			<AsyncSelect
				isMulti={props.isMulti}
				cacheOptions
				defaultOptions
				menuPlacement={"auto"}
				placeholder={props.placeholder}
				classNamePrefix="dropdown"
				closeMenuOnSelect={props.closeMenuOnSelect}
				loadOptions={search}
				value={lastOptions.find(
					(option) => option.value === props.initialValue
				)}
				onChange={handleSelect}
			/>
		</div>
	);
}

export function QuerySelect(props) {
	const [initialValues, setInitialValues] = useState(
		props?.initialValue ? props.initialValue : []
	);
	const [options, setOptions] = useState(false);

	const handleSelect = (options, new_value) => {
		if (props?.max && options?.length > props?.max) return false;
		if (props.isMulti === true) multiSelect(options, new_value);
		else {
			if (props.onSelect)
				props.onSelect(options?.value ? options.value : options);
			if (props.onChange) props.onChange(options);
			setInitialValues(options);
		}
	};

	function multiSelect(selected, new_value) {
		let new_values = selected;
		let option = new_value?.option;

		if (option?.value === "ALL")
			new_values = options
				?.filter((a) => (a.value !== "ALL" ? a : false))
				.sort((a, b) => (a.label < b.label ? -1 : 1));
		if (props.onSelect) props.onSelect(new_values);
		if (props.onChange) props.onChange(new_values);
		setInitialValues(new_values);
	}

	function setValues(items) {
		let initValues = props.initialValue;
		if (!initValues?.length) return false;
		if (typeof initValues === "string" || !initValues.length)
			initValues = [initValues];
		let res = items
			.map((option) => {
				let found = initValues.find((a) => {
					return a === option.value;
				});
				return found || !initValues?.length ? option : false;
			})
			.filter((a) => a);
		return res;
	}

	function treatItems(items) {
		let ret = [];
		if (!items?.length) return ret;
		if (props.treat)
			ret = items
				.map((a, b) => {
					return props.treat(a, b);
				})
				.filter((a) => a);
		ret = ret.sort((a, b) => {
			return props?.sortByValue
				? a.value < b.value
					? -1
					: 1
				: noAccent(a.label.toLowerCase()) <
					noAccent(b.label.toLowerCase())
					? -1
					: 1;
		});
		if (props.all && props.isMulti !== false) ret.unshift(props.all);
		return ret;
	}

	const req = useQuery(props.queryKey, props.queryFn, {
		onSuccess: (resp) => {
			let temp_items = treatItems(resp);
			setOptions(temp_items);
			if (!initialValues) {
				temp_items = setValues(temp_items);
				setInitialValues(temp_items);
				return temp_items;
			} else if (initialValues?.length) {
				let new_values = initialValues
					.map((a) => {
						let dup = a;
						for (let item of temp_items)
							if (item.value === a.value) {
								dup.label = item.label;
								return dup;
							}
						return false;
					})
					.filter((a) => a);
				setInitialValues(new_values);
			}
			return resp;
		},
	});

	const { data, isLoading } = req;

	if (isLoading) return <div>{t("Commons.LOADING")}</div>;

	let items = treatItems(data);

	return (
		<div
			className={
				"CustomAsyncSelect custom-select w-100" +
				(props.className ? " " + props.className : "")
			}
		>
			{props.title?.length && (
				<div className={"title" + (!props.text ? " mb-1" : "")}>
					{props.title}
				</div>
			)}
			{props.text && <div className="text mb-1">{props.text}</div>}
			<Select
				classNamePrefix="dropdown"
				options={items}
				isSearchable={true}
				menuPosition="fixed"
				menuPlacement={"auto"}
				{...props}
				value={props.externValue ? props.value : initialValues}
				onChange={handleSelect}
			/>
		</div>
	);
}

export function CustomSelect(props) {
	const [initialValues, setInitialValues] = useState(
		props?.initialValue ? props.initialValue : []
	);

	const handleSelect = (options, new_value) => {
		if (props?.max && options?.length > props?.max) return false;
		if (props.isMulti === true) multiSelect(options, new_value);
		else {
			if (props.onSelect)
				props.onSelect(options.value ? options.value : options);
			if (props.onChange) props.onChange(options);
			setInitialValues(options);
		}
	};

	function multiSelect(selected, new_value) {
		let new_values = selected;
		let option = new_value?.option;

		if (option?.value === "ALL")
			new_values = props.options
				?.filter((a) => (a.value !== "ALL" ? a : false))
				.sort((a, b) => (a.label < b.label ? -1 : 1));
		if (props.onSelect) props.onSelect(new_values);
		if (props.onChange) props.onChange(new_values);
		setInitialValues(new_values);
	}

	return (
		<div
			className={
				"custom-select w-100" +
				(props.className ? " " + props.className : "")
			}
		>
			{props.title?.length && (
				<div className={"title" + (!props.text ? " mb-1" : "") + " title-form"}>
					{props.title}
				</div>
			)}
			{props.text && <div className="text mb-1">{props.text}</div>}
			<Select
				{...props}
				isMulti={props.isMulti}
				placeholder={props.placeholder}
				classNamePrefix="dropdown"
				options={props.options}
				isSearchable={true}
				closeMenuOnSelect={props.closeMenuOnSelect}
				value={initialValues}
				onChange={handleSelect}
				max={props.max}
				components={props.components}
			/>
		</div>
	);
}

export default CustomAsyncSelect;
