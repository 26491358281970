import React from "react";
import FormInput from "components/Inputs/FormInput";
import MarkdownEditor from "components/MardownEditor/MarkdownEditor";
import { t } from "i18next";
import CategoriesSelect from "components/CustomSelects/CategoriesSelect";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

export default function PostContentStep(props) {
	const { account, isAdmin } = useAccount();
	const { getAppFunction } = useTemplate();
	let post = props.object;
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

	function catsSelect(values) {
		post.categories(
			values.map((a) => ({
				...a,
				CategoryId: (!a.isNew ? a.value : false),
				Name: a.label, isNew: a.isNew ? true : false,
			}))
		);
		props.refreshPreview();
	}

	return (
		<div className="inner-step-cont">
			{
				post.moderationComment() && (
					<div className="errors-cont m-2">
						<div className="font-bold">{t("Posts.MODERATION_MSG")}:</div>
						<div>{post.moderationComment()}</div>
					</div>
				)
			}
			{
				post.postTypeId() === 3 &&
				<FormInput
					onChange={(val) => {
						post.subTitle(val);
						props.refreshPreview();
					}}
					className="w-100"
					title={t("Posts.POST_SUBTITLE")}
					value={post.subTitle()}
				/>}
			<FormInput
				autoFocus={true}
				onChange={(val) => {
					post.title(val);
					props.refreshPreview();
				}}
				className="w-100"
				title={t("Posts.POST_TITLE")}
				value={post.title()}
			/>
			{
				props.categories !== false &&
				<CategoriesSelect
					noTitle={false}
					onSelect={catsSelect}
					initialValue={post.categories ? post.categories().map(a => ({ label: a.Name, value: a.CategoryId, ...a })) : false}
					type={"post-" + post.postTypeId()}
					companyId={account.CompanyId}
					className="mt-2"
				/>
			}
			<div className={clsx("mt-2", isMobile && "mobile")}>
				<div className="title-form">
					{t("Posts.POST_TEXT")}
				</div>
				<MarkdownEditor
					initialValue={post.txt()}
					toolbar={post.postTypeId() !== 13 && (getAppFunction("PostsPage.CanUseMarkdown") || isAdmin())}
					value={post.txt()}
					onChange={(val) => {
						post.txt(val);
						props.refreshPreview();
					}}
				/>
			</div>
		</div>
	);
}

export function checkPostContent(post) {
	let errors = [];

	if (post.postTypeId() === 3) {
		if (!post.subTitle()?.length)
			errors.push(t("Posts.SUBTITLE_ERROR"));
	}
	if (!post.title()?.length)
		errors.push(t("Posts.TITLE_ERROR"));
	let text = post.txt();
	if (!text?.length)
		errors.push(t("Posts.TEXT_ERROR"));
	return (errors.length ? errors : true);
}
