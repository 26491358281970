import { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import AppTemplate from "classes/AppTemplate/AppTemplate";

export default function useManifest() {
	const { account } = useAccount();
	const [manifest, setManifest] = useState(false);

	function fetchInfos(companyId, siteId) {
		let proms = [
			fetchCompany(companyId),
			fetchLogo(companyId, siteId),
			fetchTemplate(siteId),
		];
		return Promise.all(proms);
	}

	const fetchCompany = (companyId) => genRequest("Companies/" + companyId);

	function fetchLogo(companyId, siteId) {
		let prom = null;
		if (siteId) prom = fetchSiteLogo(siteId, companyId);
		else prom = fetchCompanyLogo(companyId);
		return prom;
	}

	const fetchSiteLogo = (siteId, fallback_comp_id) =>
		genRequest(
			"Documents/AllDocumentFilesOfSiteTyped/" + siteId + "/18"
		).then(
			(resp) => {
				if (!resp?.length) return fetchCompanyLogo(fallback_comp_id);
				return "data:image/png;base64," + resp[0];
			},
			() => {
				return fetchCompanyLogo(fallback_comp_id);
			}
		);

	const fetchCompanyLogo = (companyId) =>
		genRequest(
			"Documents/DocumentFilesOfCompanyAndType/" + companyId + "/18"
		).then(
			(resp) => {
				if (!resp?.length) return Promise.resolve([]);
				return "data:image/png;base64," + resp[0];
			},
			() => false
		);

	const fetchTemplate = (siteId) => {
		let prom = genRequest("Apptemplates/TemplateOfSite/" + siteId).then(
			(resp) => {
				return new AppTemplate(resp);
			}
		);
		return prom;
	};

	function genIcon(logo, size) {
		let prom = new Promise((resolve) => {
			let can = document.createElement("canvas");
			let ctx = can.getContext("2d");

			can.width = size;
			can.height = size;
			var image = new Image();
			image.onload = function () {
				ctx.drawImage(image, 0, 0, size, size);
				let img_data = can.toDataURL();
				let icon = {
					src: img_data,
					sizes: size + "x" + size,
					type: "image/png",
				};
				resolve(icon);
				return icon;
			};
			image.src = logo;
		});
		return prom;
	}

	function genIcons(logo) {
		const sizes = [
			16, 32, 64, 72, 96, 120, 128, 144, 152, 180, 192, 256, 384, 512,
			1024,
		];

		let ret = sizes.map((a) => genIcon(logo, a));
		return Promise.all(ret);
	}

	function genManifest(company, logo, template) {
		let prom = genIcons(logo).then((icons) => {
			var myDynamicManifest = {
				name: company.Name,
				short_name: company.Name,
				description: "Réseau OnlyAll " + company.Name,
				start_url: window.location.origin,
				background_color: template?.primaryColor(),
				theme_color: template?.primaryColor(),
				icons: icons,
				display: "standalone",
				companyId: company.CompanyId,
				related_applications: [
					{
						platform: "webapp",
						url: window.location.origin + "/manifest.json",
					},
				],
			};
			const stringManifest = JSON.stringify(myDynamicManifest);
			const blob = new Blob([stringManifest], {
				type: "application/json",
			});
			const manifestURL = URL.createObjectURL(blob);
			let link = document.querySelector("#manifest-link");
			link.setAttribute("href", manifestURL);
			setManifest(manifest);
			return manifestURL;
		});
		return prom;
	}

	function fetchManifest(compId, siteId) {
		let req = fetchInfos(
			compId || account.CompanyId,
			siteId || account.SiteId
		).then((resp) => genManifest(...resp));
		return req;
	}

	return {
		genManifest,
		fetchManifest,
	};
}
