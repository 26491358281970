import useAccount from "classes/Accounts/hooks/useAccount";
import DocumentObject from "classes/Documents/DocumentObject";
import WorkFamilySelect from "components/CustomSelects/WorkFamilySelect";
import WorkFunctionSelect from "components/CustomSelects/WorkFunctionSelect";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import FormInput from "components/Inputs/FormInput";
import EditableUserImage from "components/User/Image/EditableUserImage";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import SitesSelect from "components/CustomSelects/SitesSelect";

export default function UserIdentityStep(props) {
	const { isSup } = useAccount();
	const user = props.object;

	if (!props.isActive)
		return (false);

	const setImage = (data) => {
		let img = new DocumentObject({
			...data,
			IsNew: true,
			HasChanged: true,
			EmployeId: user.employesId()
		});
		user.image(img);
	};

	const selectGroups = (vals) => {
		let ret = [];
		if (!vals?.length)
			return (ret);
		ret = (vals.map((a) => ({ ...a, GroupId: a.value })));
		user.groupsToLink(ret);
		return (ret);
	};

	return (
		<>
			<div className="inner-step-cont">
				<div className="d-flex align-items-center">
					<EditableUserImage
						handleSend={setImage}
						width="100px"
						user={user.values()}
						employesId={user.employesId()}
					/>
					<div className="font-bold">
						{t("User.IMAGE")}
					</div>
				</div>
			</div>
			<div className="inner-step-cont">
				<div className="row gap-3 gap-md-0">
					<FormInput className="col-12 col-md-6" value={user.lastName()} onChange={(val) => user.lastName(val)} title={t("User.LAST_NAME")} />
					<FormInput className="col-12 col-md-6" value={user.firstName()} onChange={(val) => user.firstName(val)} title={t("User.FIRST_NAME")} />
				</div>
				<div className="row gap-3 gap-md-0 mt-3">
					<FormInput type={"email"} className="col-12 col-md-6" value={user.mail()} onChange={(val) => user.mail(val)} title={t("User.MAIL")} />
					<FormInput type={"tel"} className="col-12 col-md-6" value={user.telephone()} onChange={(val) => user.telephone(val)} title={t("User.PHONE")} />
				</div>
			</div>
			<div className="inner-step-cont">
				<div className="row">
					{
						isSup("SuperAdmin") >= 0 &&
						<div className="col-12 mb-3">
							<div className="title-form">{t("User.SITE")}</div>
							<SitesSelect
								companyId={user.companyId()}
								onSelect={(val) => user.siteId(val)}
								initialValue={user.siteId() ? [{ label: "", value: user.siteId() }] : false}
							/>
						</div>
					}
					<div className="col-12">
						<div className="title-form">{t("User.GROUPS")}</div>
						<QuerySelect
							placeholder={t("Groups.SELECT_GROUPS")}
							className={"bg-color w-100 rounded"}
							classNamePrefix="dropdown"
							queryKey={["Groups", "OfCompany", user.companyId()]}
							queryFn={() => genRequest("Groups/OfCompany/" + user.companyId())}
							treat={(grp) => ({
								value: grp.GroupId,
								GroupId: grp.GroupId,
								label: grp.Name,
								Name: grp.Name
							})}
							all={{
								label: t("Groups.ALL_GROUPS"),
								value: "ALL"
							}}
							isMulti={true}
							isSearchable={true}
							closeMenuOnSelect={false}
							onSelect={selectGroups}
							initialValue={user.linkedGroups().map(a => ({ label: a.Name, value: a.GroupId }))}
						/>
					</div>
				</div>
				<div className="row mt-3 gap-3 gap-md-0 mb-5">
					<div className="col-12 col-md-6">
						<div className="title-form">{t("User.WORK_FAM")}</div>
						<WorkFamilySelect
							companyId={user.companyId()}
							onSelect={(val) => user.workFamilyId(val)}
							initialValue={user.workFamilyId() ? [{ label: "", value: user.workFamilyId() }] : []}
						/>
					</div>
					<div className="col-12 col-md-6">
						<div className="title-form">{t("User.WORK_FUNC")}</div>
						<WorkFunctionSelect
							companyId={user.companyId()}
							onSelect={(val) => user.workFunctionId(val)}
							initialValue={user.workFunctionId() ? [{ label: "", value: user.workFunctionId() }] : []}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export function checkUserInfos(user) {
	let errors = [];

	if (!user.lastName())
		errors.push(t("Contacts.ERROR_LASTNAME"));
	if (!user.firstName())
		errors.push(t("Contacts.ERROR_FIRSTNAME"));
	if (!user.siteId())
		errors.push(t("Contacts.ERROR_SITE"));
	if (!user.workFamilyId())
		errors.push(t("Contacts.ERROR_FAMILY"));
	if (!user.workFunctionId())
		errors.push(t("Contacts.ERROR_FUNCTION"));
	errors.push(...checkContact(user));
	return ((errors?.length ? errors : true));
}

export function checkContact(user) {
	let mail_regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	let phone_regexp = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;
	let errors = [];

	if (user.telephone()?.length && !user.telephone().match(phone_regexp))
		errors.push(t("Contacts.ERROR_PHONE"));
	if (!user.mail()?.length || !user.mail().match(mail_regexp))
		errors.push(t("Contacts.ERROR_MAIL"));
	return (errors);
}
